import React from "react";
import { openPopupWidget } from "react-calendly";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
	Button, Spinner, Row,
	Col,
} from "reactstrap";
import logoTalk from "../../EllipseTalk.png";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const ProfessorList = ({ professors, profile, loaded, bootCamp, roles }) => {

	const { t } = useTranslation();
	const CustomButton = styled.button`
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  `;


	const openGoogleDriveFile = () => {
		window.open(profile.linkLiveChatNote, 'GoogleDrivePopup', 'width=1024,height=768');
	};


	return (
		<div className="project-list section">
			<div className="card-body black-text">
				<Row className="justify-content-md-center">
					<Col>
					</Col>
					<Col  >
						<span className="center">
							<img src={logoTalk} width="70%" className="logo" alt="Ellipse" />
						</span>
					</Col>
					<Col ></Col>
				</Row>
			</div>

			<h4 className="my-4">{t('professor_list_my_coaches')}</h4>
			<div>
				<CustomButton
					onClick={openGoogleDriveFile}
					style={{ float: 'left', fontSize: '12px', padding: '5px 10px', backgroundColor: profile.linkLiveChatNote == null ? '#ccc' : '#007bff', color: profile.linkLiveChatNote == null ? '#666' : '#fff', cursor: profile.linkLiveChatNote == null ? 'not-allowed' : 'pointer', opacity: profile.linkLiveChatNote == null ? '0.6' : '1' }}
					disabled={profile.linkLiveChatNote == null}
				>Open My Chat Notes</CustomButton>
				<br></br>
			</div>
			{!loaded ? (
				<div className="text-center mt-3">
					<Spinner />
				</div>
			) : (
				<div className="container">
					{professors && professors.length > 0 ? (
						<h6 className="my-4">
							{t('professor_list_hours_remaining')} : {profile.numberHoursRemaining}h /{" "}
							{profile.numberHourBuy}h
						</h6>) : ""}
					<div >
						{professors && professors.length > 0 ? (
							<Carousel showThumbs={false}>
								{professors &&
									getRandom(professors).filter(ll => (ll.retired == false || ll.retired == null)).map(professor => {
										return (
											<div className="professor center grey lighten-4" key={professor.name}>
												<div className="carcontent black-text">
													<div className="professor-image">
														<img
															className="rounded-circle responsive-img"
															src={
																professor.pictureUrl ? professor.pictureUrl : null
															}
															alt={professor.name}
														/>
													</div>
													<h5 className="professor-name">{professor.name}</h5>
													<p>
														<strong>{t('professor_list_interests')}</strong> <br></br>
														{professor.interest}
													</p>

													<Button
														className="booking-btn btn white-text"
														color="primary"
														disabled={profile.numberHoursRemaining <= 0}
														onClick={() =>
															openPopupWidget({
																url:
																	"https://calendly.com" + professor.urlCalendly,
																prefill: {
																	name: profile.firstname,
																	email: profile.email,
																},
																utm: {
																	utmSource: profile.uid
																}
															})
														}
													>
														{t('professor_list_schedule_meeting')}
													</Button>
												</div>
											</div>
										);
									})}
							</Carousel>) : (

							<div className="center red-text-big">
								{t('professor_list_error_no_coaches')}
							</div>

						)}
					</div>
				</div>

			)}
		</div>

	);
};

function getRandom(professors) {
	const shuffledArray = [...professors];
	for (let i = shuffledArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray;


}

export default ProfessorList;
