
import React, { useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import DataTable from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import {
    FormGroup,
    Label,
    Button,
    Modal, ModalHeader, ModalBody,
} from "reactstrap";
import input from "./input";
import TimePicker from 'react-time-picker';
import DateTimePicker from 'react-datetime-picker';
import AddStudent from "./addStudentModal2";


import DeleteConfirmation from "./deleteConfirmation";




function MastermindsList(props) {
    const [isLoading, setLoading] = useState(false);
    const [idRowClicked, setIdRowClicked] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [type, setType] = useState(null);
    const [idTodelete, setIdTodelete] = useState(null);
    const [startTime, onChangeStartTime] = useState('07:30');
    const [endTime, onChangeEndTime] = useState('08:00');
    const [mastermindObj, setMastermindObj] = useState({
        duration: "",
        eventWeeks: [],
        name: "",
        particupants: [],
        startDate: "",
        createdTime: "",
        updatedTime: "",
    });

    const [weekObj, setWeekObj] = useState({
        date: "",
        prensatationLink: "",
        subject: "",
    });

    const addWeekObj = () => {
        const newWeek = { ...weekObj }; // Copiez weekObj pour éviter de modifier l'original
        setMastermindObj((prevMastermindObj) => ({
            ...prevMastermindObj,
            eventWeeks: [...prevMastermindObj.eventWeeks, newWeek],
        }));
    };

    const addRow = () => {
        setIsAdd(true)
        mastermindObj.createdTime = new Date()
        mastermindObj.updatedTime = new Date()
        mastermindObj.duration = 0
        mastermindObj.eventWeeks = []
        mastermindObj.particupants = []
        mastermindObj.startDate = new Date()
    };

    const onRowClickedDelete = (row, event) => {
        showDeleteModal(row.name, row.id)
    };

    const showDeleteModal = (name, id) => {

        setDeleteMessage(`Are you sure you want to delete the sprint '${name}'?`);
        setIdTodelete(id)

        setDisplayConfirmationModal(true);

    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitDelete = () => {

        deleteSprint(idTodelete)
        setDisplayConfirmationModal(false);
    };

    const handleSubmitSprint = async evt => {

        mastermindObj.createdTime = new Date()
        mastermindObj.updatedTime = new Date()
        mastermindObj.duration = evt.duration
        mastermindObj.eventWeeks = evt.eventWeeks
        mastermindObj.particupants = evt.particupants
        mastermindObj.startDate = evt.startDate

        if (isAdd) {
            await createMastermind(mastermindObj)
        }

        if (isModify) {
            await updateMastermind(mastermindObj)
        }

        setIsAdd(false)
        setIsModify(false)
        this.forceUpdate();
    };

    const createMastermind = (mastermindObj) => {

        const firestore = firebase.firestore();

        firestore
            .collection("masterminds")
            .add(mastermindObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    const deleteMastermind = (id) => {
        const firestore = firebase.firestore();


        firestore
            .collection("masterminds")
            .doc(id)
            .delete()
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    //  Update Sprint
    const updateMastermind = (mastermindObj) => {
        const firestore = firebase.firestore();


        firestore
            .collection("masterminds")
            .doc(idRowClicked)
            .update(mastermindObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };


    const columnsMaterminds = [
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-trash`} onClick={() => onRowClickedDelete(row)}>
                    </i>
                </div>
            ),
            width: "50px",

        },

        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClicked(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Duration (weeks)',
            selector: row => row.duration,
            sortable: true,
            width: "300px",
        },
    ];

    const schemaSprints = yup.object({
        name: yup.string().required("Name is required"),
        level: yup.string().required("Level is required"),
        disabled: yup.string().required("Disabled is required"),
    });


    const columnsParticipants = [
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-trash`} onClick={() => onRowClickedDeleteStudent(row)}>
                    </i>
                </div>
            ),
            width: "50px",

        },
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClickedStudent(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "300px",
        },
    ];


    const [studentObj, setStudentObj] = useState({

        firstName: "",
        lastName: "",
        email: "",
    });

    const AddStudentModal = props => {
        const { isOpen } = props;
        // If we only put the modal in the render tree when it's open, multiple modals
        // will open in the expected order
        return isOpen ? <Modal {...props} /> : null;
    };

    //*************************************************************************************************** */
    //                       STUDENT
    //*************************************************************************************************** */

    const [isAddStudent, setIsAddStudent] = useState(false);
    const [isModifyStudent, setIsModifyStudent] = useState(false);
    const [displayAddStudent, setDisplayAddStudent] = useState(false);
    const [nameStudentToDelete, setNameStudentToDelete] = useState("");
    const [deleteStudentMessage, setDeleteStudentMessage] = useState(`Are you sure you want to delete this student '${nameStudentToDelete}'?`);
    const [idStudentTodelete, setIdStudentTodelete] = useState(null);
    const [displayStudentConfirmationModal, setDisplayStudentConfirmationModal] = useState(false);
    const [idRowClickedStudent, setIdRowClickedStudent] = useState(null);

    const hideStudent = () => {
        setDisplayAddStudent(false);
    };
    // Hide the modal
    const hideStudentConfirmationModal = () => {
        setDisplayStudentConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitStudentDelete = () => {
        let array = JSON.parse(JSON.stringify(companyObj.students));

        let obj = array.filter(
            item => item.email != idStudentTodelete
        );
        companyObj.students = obj

        setDisplayStudentConfirmationModal(false);
    };

    const addRowStudent = () => {
        setIsModifyStudent(false)
        setDisplayAddStudent(true)
    };

    const onRowClickedStudent = (row, event) => {

        studentObj.lastName = row.lastName
        studentObj.firstName = row.firstName
        studentObj.email = row.email

        setIdRowClickedStudent(row.id)
        setIsAddStudent(false)
        setIsModifyStudent(true)
        setDisplayAddStudent(true)
    };

    const onRowClickedDeleteStudent = (row, event) => {
        setIdStudentTodelete(row.email)
        setNameStudentToDelete(row.firstName + ' ' + row.lastName)

        setDeleteStudentMessage("Are you sure you want to delete this student " + row.firstName + ' ' + row.lastName + " ?")

        setDisplayStudentConfirmationModal(true);

    };


    //*************************************************************************************************************** */


    const { masterMindDB, usersDB, profile } = props;

    if (!isLoaded(masterMindDB) || !isLoaded(profile) || !isLoaded(usersDB)) {

        return (
            <div className="text-center mt-3">
                <Spinner />
            </div>
        )
    } else {
        return (

            <div className="dashboard container">
                <div className="row"> </div>
                <div className="row">
                    <div className="col s12">

                        <div className="form">

                            <center><h4 className="my-4">Masterminds</h4>

                                <div align="right">
                                    <div>
                                        <i className={`icon-container fas fa-plus-circle white-space:nowrap`} onClick={() => addRow()}>&nbsp;Add
                                        </i>

                                    </div>
                                </div>
                            </center>
                            {(isAdd || isModify) && (
                                <Formik
                                    validationSchema={schemaSprints}
                                    onSubmit={handleSubmitSprint}
                                    initialValues={mastermindObj || {}}
                                >
                                    {({
                                        handleSubmitSprint,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isInvalid,
                                        errors,
                                    }) => (
                                        <Form >
                                            <FormGroup>
                                                <Label>Name of the mastermind</Label>
                                                <Field name="name" type="text" component={input} placeholder="Name" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Start date</Label>
                                                <Field name="startDate" type="text" component={input} placeholder="Title" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label><b>Participant for the Mastermind</b></Label><i className={`icon-container fas fa-plus-circle white-space:nowrap`} onClick={() => addRowStudent()} />
                                                <DataTable
                                                    columns={columnsParticipants}
                                                    data={mastermindObj.participants}
                                                    onRowClicked={onRowClickedStudent}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label><b>Event weeks</b></Label><i className={`icon-container fas fa-plus-circle white-space:nowrap`} onClick={() => addRowStudent()} />
                                                <DataTable
                                                    columns={columnsParticipants}
                                                    data={mastermindObj.participants}
                                                    onRowClicked={onRowClickedStudent}
                                                />
                                            </FormGroup>
                                            
                                            <Button type="submit" style={{ marginRight: "10px" }}>
                                                Save
                                            </Button>
                                            <Button type="button" onClick={() => {
                                                setIsAdd(false)
                                                setIsModify(false)
                                            }}>
                                                Cancel
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            )}

                            <div>
                                {(!isAdd && !isModify) && (
                                    <DataTable
                                        columns={columnsMaterminds}
                                        data={masterMindDB}
                                    />)
                                }
                            </div>


                            <br></br>

                            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} type={type} id={idTodelete} message={deleteMessage} btnLabel="Delete" />
                            <AddStudentModal isOpen={displayAddStudent} size="lg" toggle={() => setDisplayAddStudent(false)}>

                                <ModalHeader>
                                    <h4>Add a student to the Sprint</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <AddStudent
                                        onCancel={hideStudent.bind(this)}
                                        student={studentObj}
                                        idCompany={idRowClicked}
                                        displayAddStudent={displayAddStudent}
                                        isModify={isModifyStudent}
                                    />
                                </ModalBody>
                            </AddStudentModal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapToProps = state => {
    return {
        masterMindDB: state.firestore.ordered.masterminds,
        usersDB: state.firestore.ordered.users,
        profile: state.firebase.profile,
    };
};

export default compose(
    connect(mapToProps),
    firestoreConnect([{ collection: "masterminds" }, { collection: "users" }])
)
    (MastermindsList);

