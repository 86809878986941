
import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback,
} from "reactstrap";
import input from "../administration/input";
import firebase from "firebase";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Spinner } from "reactstrap";
import styled from 'styled-components';
import DeleteConfirmation from "./deleteConfirmation";
import "./CollapsibleDiv.css"; // Assurez-vous d'avoir un fichier CSS pour le style du collapsible div.



function ContactForm({
    edit,
    profile,
    student,
    onCancel, 
    updateHoursRemaining
}) {


    // Set up some additional local state
    const [type, setType] = useState(null);
    const [totalHours, setTotalHours] = useState(0);
    const [idTodelete, setIdTodelete] = useState(null);
    const [duration, setDuration] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [idRowClicked, setIdRowClicked] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [professorName, setProfessorName] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [learningLogs, setLearningLogs] = useState([]);
    const [professorDB, setProfessorDB] = useState([]);

    const firestore = firebase.firestore();





    useEffect(() => {

        const professorDB = async () => {
            const professorDBRef = firestore.collection("professor");
            const querySnapshot = await professorDBRef.get();
            const professors = [];
            querySnapshot.forEach((doc) => {
                professors.push({ id: doc.id, ...doc.data() });
            });
            setProfessorDB(professors)
        };

        professorDB(); // Appel de la fonction pour récupérer les données


        fetchLearningLogs(); // Appel de la fonction pour récupérer les données


    }, [student]);

    const fetchLearningLogs = async () => {
        let nbHourInLearningLog = 0;
        try {

            const learningLogsRef = firestore.collection("learningLogs");


            const queryByUserId = learningLogsRef
                .where("userId", "==", student.id)
                .orderBy("date", "desc")
                .get();

            const [querySnapshotByUserId, querySnapshotByUid] = await Promise.all([
                queryByUserId,
            ]);

            const logs = [];

            querySnapshotByUserId.forEach((doc) => {

                if (doc.data().retired != true)
                    logs.push({ id: doc.id, ...doc.data() });
            });



            setLearningLogs(logs);
            logs.forEach(elt => nbHourInLearningLog += elt.duration === '' ? 0 : elt.duration);
            setTotalHours(nbHourInLearningLog);
        } catch (error) {
            console.error("Erreur lors de la récupération des données", error);
        }
    };



    const schema = yup.object({
        date: yup.string().required("Date is required"),
        duration: yup.number().required("Duration is required"),
        duration: yup.number().min(0).max(3).required(),
        discussion: yup.string().required("Disccussion is required"),
    });

    const [learningLogObj, setLearningLogObj] = useState({
        userId: "",
        date: "",
        professorId: "",
        duration: 0,
        discussion: "",
        retired: false,
        createdTime: "",
        updatedTime: "",
    });

    const handleSubmit = async evt => {
        const isValid = await schema.validate(evt);

        if (!isValid) {
            return;
        }

        learningLogObj.userId = student.id
        if (isAdd) {
            learningLogObj.professorId = getProfessorId(profile.email)
            learningLogObj.createdTime = new Date()
        }
        learningLogObj.date = evt.date
        learningLogObj.duration = evt.duration
        learningLogObj.discussion = evt.discussion
        if (await isModify) {
            learningLogObj.createdTime = evt.createdTime
        }
        learningLogObj.updatedTime = new Date()

        if (isAdd) {
            await createLearningLog(learningLogObj)
        }

        if (isModify) {
            await updateLearningLog(learningLogObj)
        }

        setIsAdd(false)
        setIsModify(false)
        fetchLearningLogs();
        this.forceUpdate();

    };



    const onRowClicked = (row, event) => {
        learningLogObj.discussion = row.discussion
        learningLogObj.date = row.date
        learningLogObj.duration = row.duration
        learningLogObj.userId = row.userId
        learningLogObj.professorId = row.professorId
        //learningLogObj.id = row.id

        setIdRowClicked(row.id)
        setIsAdd(false)
        setIsModify(true)
        setProfessorName(getProfessorName(row.professorId))
    };


    const onRowClickedDelete = (row, event) => {

        showDeleteModal(row.date, profile.firstname + " " + profile.lastname, row.id, row.duration)


    };


    const addRow = () => {
        setIsAdd(true)
        setProfessorName(getProfessorName(getProfessorId(profile.email)))
        learningLogObj.discussion = ""
        learningLogObj.date = ""
        learningLogObj.duration = ""
        learningLogObj.userId = ""
        learningLogObj.professorId = ""
    };

    const getProfessorName = (id) => {
        let professor = professorDB.find(prof => prof.id === id)
        if (professor != null) {

            return professor.name
        }
        return "N/A"

    };


    const getProfessorId = (email) => {
        let professor = professorDB.find(prof => prof.email === email)
        if (professor != null) {
            setProfessorName(professor.name)
            return professor.id
        }
        return null

    };

    const btnSaveDisable = () => {
        if (profile.role != null && profile.role.indexOf("admin") > -1 || learningLogObj.professorId == getProfessorId(profile.email) || isAdd) {

            return false;
        } else
            return true

    };



    const createLearningLog = (learningLogObj) => {
        const firestore = firebase.firestore();
    
        firestore
            .collection("learningLogs")
            .add(learningLogObj)
            .then(() => {
                console.log("Learning log successfully created.");
    
                // Update remaining hours after the learning log is successfully created
                updateRemainingHourNew(learningLogObj);
            })
            .catch(error => {
                console.error("Error creating learning log:", error.message);
            });
    };
    
    const deleteLearningLog = (id) => {
        const firestore = firebase.firestore();
    
        firestore
            .collection("learningLogs")
            .doc(id)
            .delete()
            .then(() => {
                console.log("Learning log successfully deleted.");
    
                // Fetch the learning logs and update remaining hours after deletion
                fetchLearningLogs().then(() => {
                    updateRemainingHourDelete(id);
                });
            })
            .catch(error => {
                console.error("Error deleting learning log:", error.message);
            });
    };
    
    // Update Learning Logs
    const updateLearningLog = (learningLogToUpdate) => {
        const firestore = firebase.firestore();
    
        firestore
            .collection("learningLogs")
            .doc(idRowClicked)
            .update(learningLogToUpdate)
            .then(() => {
                console.log("Learning log successfully updated.");
    
                // Update remaining hours after the learning log is successfully updated
                updateRemainingHourUpdate(learningLogToUpdate);
            })
            .catch(error => {
                console.error("Error updating learning log:", error.message);
            });
    };
    


    const updateRemainingHours = (actionDescription) => {
        const firestore = firebase.firestore();
    
        return firestore.collection('users').doc(student.id).get()
            .then(resp => {
                const user = resp.data();
    
                if (!user) {
                    console.error("User data not found");
                    return;
                }
    
                // Call updateHoursRemaining to calculate and update the remaining hours
                return updateHoursRemaining(user)
                    .then(remainingHours => {
                        console.log(`Updated remaining hours ${actionDescription}: ${remainingHours}`);
    
                        // Update the user document with the new remaining hours
                        return firestore.collection("users")
                            .doc(student.id)
                            .update({ numberHoursRemaining: remainingHours })
                            .then(() => {
                                console.log(`Remaining hours successfully updated in Firestore ${actionDescription}.`);
                            })
                            .catch(error => {
                                console.error(`Error updating remaining hours in Firestore ${actionDescription}:`, error.message);
                            });
                    })
                    .catch(error => {
                        console.error(`Failed to update remaining hours ${actionDescription}:`, error.message);
                    });
            })
            .catch(error => {
                console.error("Error fetching user data:", error.message);
            });
    };
    
    // Update number hour remaining after a new learning log is added
    const updateRemainingHourNew = (learningLogObj) => {
        updateRemainingHours('after adding a new log');
    };
    
    // Update number hour remaining after a learning log is updated
    const updateRemainingHourUpdate = (learningLogObj) => {
        updateRemainingHours('after updating a log');
    };
    
    // Update number hour remaining after a learning log is deleted
    const updateRemainingHourDelete = (duration) => {
        updateRemainingHours('after deleting a log');
    };
    






    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (date, name, id, duration) => {

        setDeleteMessage(`Are you sure you want to delete the entry '${date}' in the learning log?`);
        setIdTodelete(id)
        setDuration(duration)

        setDisplayConfirmationModal(true);

    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitDelete = () => {

        deleteLearningLog(idTodelete)
        setDisplayConfirmationModal(false);
    };


    const [showContent, setShowContent] = useState(false);

    const toggleContent = () => {
        setShowContent(!showContent);
    };

    const [showContentActions, setShowContentActions] = useState(false);

    const toggleContentActions = () => {
        setShowContentActions(!showContentActions);
    };

    const columns = [
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClicked(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            width: "175px",
        },
        {
            name: 'Duration',
            selector: row => row.duration,
            width: "80px",
        },
        {
            name: 'Professor',
            selector: row => getProfessorName(row.professorId),
            width: "100px",
        },
        {
            name: 'Discussion',
            selector: row => row.discussion,
            width: "500px",
        },

    ];

    const columnsAmin = [
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-trash`} disable={true} onClick={() => onRowClickedDelete(row)}>
                    </i>
                </div>
            ),
            width: "50px",

        },

        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClicked(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            width: "175px",
        },
        {
            name: 'Duration (hour)',
            selector: row => row.duration,
            width: "80px",
        },
        {
            name: 'Coach',
            selector: row => getProfessorName(row.professorId),
            width: "100px",
        },
        {
            name: 'Discussion',
            selector: row => row.discussion,
            width: "500px",
        },

    ];

    const googleDriveLink = 'https://docs.google.com/document/d/1RgPngc5zu67lE7D4V_DWnpjx6QwzOhONqPd9v_ucn6g/edit?usp=sharing';

    const openGoogleDriveFile = () => {
        window.open(student.linkLiveChatNote, 'GoogleDrivePopup', 'width=1024,height=768');
    };

    const CustomButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


    if (!isLoaded(professorDB)) {
        return (
            <div className="text-center mt-3">
                <Spinner />
            </div>
        )
    } else {


        return (
            <div className="form">


                {isAdd && (<div align="center">
                    <div>
                        <h4>Create a new entry</h4>
                    </div>
                </div>)}

                {isModify && (<div align="center">
                    <div>
                        <h4>Update the entry</h4>
                    </div>
                </div>)}


                {(isAdd || isModify) && (
                    <Formik
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                        initialValues={learningLogObj || {}}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isInvalid,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label>Date</Label>
                                    <Field name="date" type="date" component={input} max={moment(new Date()).format("YYYY-MM-DD")} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Duration (Hour)</Label>
                                    <Field name="duration" type="number" step="0.5"
                                        component={input} defaultValue="0" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Coach</Label>
                                    <Field name="professor" type="text" component={input} readOnly={true} placeholder={professorName} />
                                </FormGroup>
                                <FormGroup>
                                    <Field name="discussion" type="textarea" component={input} rows={4} placeholder="Content discussion here" />
                                </FormGroup>

                                <Button type="submit" style={{ marginRight: "10px" }} disabled={btnSaveDisable()} >
                                    Save
                                </Button>
                                <Button type="button" onClick={() => {
                                    setIsAdd(false)
                                    setIsModify(false)
                                }}>
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>)}
                {(!isAdd && !isModify && student.confidenceObjective1) && (
                    <div className="collapsible">
                        <button onClick={toggleContent}>
                            {showContent ? "Hide Objectives" : "Show Objectives"}
                        </button>
                        {showContent && student.confidenceObjective1 && (
                            <div className="content">
                                <ol>
                                    <li>{student.confidenceObjective1}</li>
                                    <li>{student.confidenceObjective2}</li>
                                    <li>{student.confidenceObjective3}</li>
                                </ol>
                            </div>
                        )}
                        {!student.confidenceObjective1 && (
                            <div className="content">
                                No objective defined
                            </div>
                        )}
                    </div>)}
                {(!isAdd && !isModify && student.confidenceObjective1) && (
                    <div className="collapsible">
                        <button onClick={toggleContentActions}>
                            {showContentActions ? "Hide Actions" : "Show Actions"}
                        </button>
                        {showContentActions && (
                            <div className="content">
                                <p><ul>
                                    {student.actionPlans &&
                                        student.actionPlans.map((actionPlan, index) => (
                                            <li>{actionPlan.description}</li>

                                        ))}
                                    {!student.actionPlans && (
                                        <div className="content">
                                            No action defined
                                        </div>
                                    )}
                                </ul></p>
                            </div>
                        )}
                    </div>)}

                {!isAdd && !isModify && (
                    <div style={{ textAlign: 'right' }}>
                        <div>
                            <CustomButton
                                onClick={openGoogleDriveFile}
                                style={{
                                    float: 'left',
                                    fontSize: '12px',
                                    padding: '5px 10px',
                                    backgroundColor: student.linkLiveChatNote == null ? '#ccc' : '#007bff',
                                    color: student.linkLiveChatNote == null ? '#666' : '#fff',
                                    cursor: student.linkLiveChatNote == null ? 'not-allowed' : 'pointer',
                                    opacity: student.linkLiveChatNote == null ? '0.6' : '1'
                                }}
                                disabled={student.linkLiveChatNote == null}
                            >Open My Chat Notes</CustomButton>
                            <i className="icon-container fas fa-plus-circle" onClick={addRow}></i>
                            <span style={{ marginLeft: '5px' }}>Add an Entry</span>
                            <br />
                        </div>
                    </div>)}


                {(!isAdd && !isModify) && (

                    <center>
                        <h4 className="my-4">Learning log entry</h4>

                        <small className="my-4">Total hours: {totalHours}</small>
                    </center>

                )
                }


                {(!isAdd && !isModify) && (

                    <DataTable
                        columns={profile.role != null && profile.role.indexOf("admin") > -1 ? columnsAmin : columns}
                        data={learningLogs.filter(ll => (ll.retired == false || ll.retired == null)).sort((a, b) => a.date > b.date ? -1 : 1)}
                        defaultSortFieldId={1}
                        pagination
                        onRowClicked={onRowClicked}
                    />)
                }

                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} type={type} id={idTodelete} message={deleteMessage} />

            </div>
        );
    }
}
ContactForm.propTypes = {
    edit: PropTypes.bool,
    profile: PropTypes.object,
    student: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default (ContactForm);

