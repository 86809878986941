
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import DataTable from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import firebase from "firebase";

import axios from 'axios';





function SprintsForm(props) {
    //*********************************** Student ************************** */
    const [isLoading, setLoading] = useState(true);
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Months are 0-indexed (0 for January, 11 for December)
    const currentYear = currentDate.getFullYear();

    const [usersFiltered, setUsersFiltered] = useState([]);

    const [companyName, setCompanyName] = useState([]);

    /*const [companyObj, setcompanyObj] = useState({

        name: "",
        numberSeatsSprint: 0,
        students: [],
        totalPrivateHours: 0,
    });*/


    const highlightStyle = {
        style: {
            backgroundColor: '#f7d794', // Couleur de fond pour les rangées en surbrillance
            color: '#000', // Couleur du texte
        }
    };

    const conditionalRowStyles = [
        {
            when: row => calculateUserMonthlySprintAttendance(row) === Math.max(...usersFiltered.map(user => calculateUserMonthlySprintAttendance(user))),
            style: {
                backgroundColor: '#f7d794',
                color: '#000',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#f6e58d',
                },
            },
        },
    ];

    const { profile } = props;


    //const filteredUsers = usersDB.filter(item => item.company === companyObj.id && !item.role.includes("admin"));


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!profile) {
                    setLoading(false);
                    return; // If no company ID provided, stop fetching data
                }

                const querySnapshot = await firebase
                    .firestore()
                    .collection('users')
                    .where('company', '==', profile.company)
                    .where('role', '!=', 'admin')
                    .get();

                const userData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const companyRef = firebase.firestore().collection('company').doc(profile.company);

                const docSnapshot = await companyRef.get();

                let companyNameSnap ="N/A";

                if (docSnapshot.exists) {
                    companyNameSnap = docSnapshot.data().name; // Supposant que 'name' est le champ contenant le nom de l'entreprise
                } else {
                    console.log("No such document!"); // Gestion du cas où le document n'existe pas
                }


                setCompanyName(companyNameSnap);
                setUsersFiltered(userData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [profile.company]); // This effect depends on `companyId`


    const columnsStudents = [
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: true,
            width: "150px", // Adjusted for typical first name lengths
        },
        {
            name: 'Last Name',
            selector: row => row.lastname,
            sortable: true,
            width: "150px", // Adjusted for typical last name lengths
        },
         {
             name: 'Email',
             selector: row => row.email,
             sortable: true,
             width: "230px", // Provides more space for email which can be longer
         },
        {
            name: 'Available hours',
            center: true,
            selector: row => row.numberHoursRemaining != null ? row.numberHoursRemaining + "/" + row.numberHourBuy : "N/A",
            sortable: true,
            width: "130px", // Adjusted to fit the potentially complex information
        },
        {
            name: 'Sprint Current Month (' + monthNames[currentMonth] + ')',
            center: true,
            selector: row => calculateUserMonthlySprintAttendance(row) + " Sessions",
            sortable: true,
            width: "200px", // Provides space for the text and numbers
        },
        {
            name: 'Sprint Attendance',
            center: true,
            selector: row => row.sprintAttendance != null ? row.sprintAttendance.length + " Sessions" : "0 Sessions",
            sortable: true,
            width: "200px", // Matched width with 'Sprint Current Month' for consistency
        },
    ];
    /* {
         name: 'Attendance to Sprint',
         center: true,
         cell: (row) => (
             <div>
                 <i className={`icon-container far fa-address-book`} onClick={() => onRowClickedPauseStudent(row)}></i>
 
             </div>
         ),
         width: "200px",
     },*/
    // ];

    function calculateUserMonthlySprintAttendance(user) {
        

        // Check if sprintAttendance exists and is an array; otherwise, set it as an empty array
        const attendanceRecords = user.sprintAttendance || [];
        console.log("user", user);

        if (user.email == "lanm80@gmail.com")
            console.log("attendanceRecords", attendanceRecords[0].toDate());

        // Filter user's sprint attendances to only include those within the current month and year
        const monthlyAttendance = attendanceRecords.filter(date => {
            const attendanceDate = date.toDate();
            return attendanceDate.getMonth() === currentMonth && attendanceDate.getFullYear() === currentYear;
        }).length;

        return monthlyAttendance;
    }


    function getCompanyName() {

        // Check if sprintAttendance exists and is an array; otherwise, set it as an empty array
        const attendanceRecords = user.sprintAttendance || [];

        // Filter user's sprint attendances to only include those within the current month and year
        const monthlyAttendance = attendanceRecords.filter(date => {
            const attendanceDate = new Date(date);
            return attendanceDate.getMonth() === currentMonth && attendanceDate.getFullYear() === currentYear;
        }).length;

        return monthlyAttendance;
    }

    /*const onRowClickedPauseStudent = (row) => {
        setLoading(true);

        axios.get("https://script.google.com/macros/s/AKfycbxpYsO7UlX461m7HJl2VVq8fFTYs5pG6CEtD4djrTUX90dfEz9MuUvttmG47FLlwD7N/exec?email=lanm80@gmail.com&startDate=&endDate=&name=" + row.firstname + " " + row.lastname + "&emailtoproduce=" + row.email)
            .then(res => {
                const pdfWindow = window.open();
                pdfWindow.location.href = res.data;
                setLoading(false);
                this.forceUpdate();

            })
            .catch(error => {
                setLoading(false);
            })

    };*/
    console.log("isLoading", isLoading);

    if (!isLoaded(profile) || isLoading) {
        return (
            <div className="text-center mt-3">
                <Spinner />
            </div>
        )
    } else {

        /*let obj = companiesDB.find(
            item => item.id == profile.company
        );

        if (obj) {

            companyObj.name = obj.name
            companyObj.id = obj.id
            companyObj.numberSeatsSprint = obj.numberSeatsSprint == null ? 0 : obj.numberSeatsSprint
            companyObj.students = obj.students == null ? [] : obj.students
            companyObj.totalPrivateHours = obj.totalPrivateHours == null ? 0 : obj.totalPrivateHours
            companyObj.debitDay = obj.debitDay == null ? 0 : obj.debitDay
        }*/

        return (
            <div className="dashboard container">
                <div className="row"> </div>
                <div className="row">
                    <div className="col s12">

                        <div className="form">
                            {isLoading ? <Spinner /> :
                                <center><h4 className="my-4">User management ({companyName})</h4>
                                    <div>
                                        <DataTable
                                            columns={columnsStudents}
                                            //data={usersDB.filter(item => item.company == companyObj.id && item.role != null && item.role.indexOf("admin") == -1)}
                                            data={usersFiltered}
                                            //conditionalRowStyles={conditionalRowStyles}
                                            defaultSortFieldId={5}
                                            defaultSortAsc={false} // Tri descendant
                                        />
                                    </div>
                                </center>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapToProps = state => {
    return {
        profile: state.firebase.profile,
    };
};

export default compose(
    connect(mapToProps)
)
    (SprintsForm);

