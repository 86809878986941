import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import './SearchUsers.css'; // Créez un fichier CSS pour les styles personnalisés
import ContactForm from "./learningLogs";
import usersAdministration from "./usersAdministration";
import UserAdministrationModal from '../administration/studentAdministration';
import firebase from "firebase";

function SearchUsers(props) {
	const { users, auth, profile, t, company, mastermind, contracts } = props;
	const [isSearch, setSearch] = useState(false);
	const [searchObj, setSearchObj] = useState({
		email: "",
		firstname: "",
		lastname: "",
	});

	const [paginatedResults, setPaginatedResults] = useState(null);


	let filteredResultsTmp = null;
	let startIndex = null;
	let endIndex = null;

	//********************************UserAdministration********************************************* */
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const openModalUser = (userModal) => {
		if (userModal) {
			setIsAdd(false);
			setIsModify(true);
		}
		else {
			setIsAdd(true);
			setIsModify(false);
		}

		setSelectedUser(userModal);
		setIsModalOpen(true);
	};

	const closeModalUser = () => {
		setSelectedUser(null);
		setIsModalOpen(false);
	};

	const saveUser = (updatedUser) => {
		const firestore = firebase.firestore();

		// Call updateHoursRemaining to calculate the remaining hours

		updateHoursRemaining(updatedUser).then(numberHoursRemainingUser => {
			// Create a new object with updated values
			const updatedUserData = {
				...updatedUser,
				numberHoursRemaining: numberHoursRemainingUser
			};

			if (isAdd) {
				firestore
					.collection("users")
					.add(updatedUserData)
					.then(() => {
						// Handle success
					})
					.catch(error => console.log(error.message));
			} else {
				let emailChanged = selectedUser.email != updatedUserData.email;
				if (emailChanged) {
					updatedUserData.email = updatedUserData.email.toLowerCase();
					updatedUserData.oldEmail = selectedUser.email.toLowerCase();
					updatedUserData.emailChanged = true;
				}

				firestore
					.collection("users")
					.doc(updatedUser.id)
					.update(updatedUserData)
					.then(() => {
						// Handle success
					})
					.catch(error => console.log(error.message));
			}
		})
		.catch(error => {
            console.error("Error in saveUser:", error);
            // Handle the error appropriately
        });
	};
	const stripTime = (date) => {
		try {
		  return date.toDate().toISOString().split('T')[0]; // Format ISO (YYYY-MM-DD)
		}
		catch (error) {
		  return date; // Return the original date if an error occurs
		}
	  };
	  

	  const updateHoursRemainingWithPeriod = (updatedUser) => {
		const firestore = firebase.firestore();
		const today = new Date().toISOString().split('T')[0]; // Format ISO (YYYY-MM-DD)
		console.log("updateHoursRemainingWithPeriod", updatedUser);
	  
		// Vérifier si updatedUser ou son id est null ou undefined
		if (!updatedUser || !updatedUser.id) {
		  console.error("Invalid updatedUser provided to updateHoursRemaining", updatedUser);
		  return Promise.resolve(0); // Résoudre avec 0 au lieu de rejeter
		}
	  
		return new Promise((resolve, reject) => {
		  firestore.collection('learningLogs')
			.where("userId", "==", updatedUser.id)
			.get()
			.then(resp => {
			  let totalHoursRemaining = 0;
			  let learningLogs = resp.docs.map(doc => doc.data());
	  
			  // Trier et filtrer les heures achetées par date d'achat
			  const sortedHoursPurchased = updatedUser.hoursPurchased
				.filter(hour => {
				  const adjustedEndDate = stripTime(hour.endDate);
				  const purchaseDate = stripTime(hour.datePurchase);
	  
				  // Vérifier que la date actuelle est dans la période d'achat et que l'heure n'est pas retirée
				  return !hour.retired ;
				})
				.sort((a, b) => new Date(a.datePurchase) - new Date(b.datePurchase)); // Trier par date d'achat

					  const updatedHoursPurchased = sortedHoursPurchased.map(hourPurchased => ({
						...hourPurchased,
						hoursTaken: 0,
						remainingHours: hourPurchased.numberOfHours,
						usedLogDates: [],
					  }));


					        // Calculate hours taken and borrow across periods if needed
      totalHoursRemaining =calculateHoursTaken(learningLogs, updatedHoursPurchased);
	 /* console.log("hr", hr);
	  
			  // Pour chaque banque d'heures achetée
			  sortedHoursPurchased.forEach(hourPurchased => {
				let hoursUsedInPeriod = 0;

				console.log("hourPurchased", hourPurchased);
	  
				// Filtrer les learningLogs qui peuvent être affectés à cette période
				const availableLogs = learningLogs.filter(log => {
				  const logDate = new Date(log.date);
				  return logDate >= new Date(hourPurchased.datePurchase) && logDate <= new Date(hourPurchased.endDate);
				});
	  
				// Calculer les heures utilisées dans cette période
				availableLogs.forEach(log => {
				  const logDuration = parseFloat(log.duration) || 0;
				  const remainingCapacity = parseFloat(hourPurchased.numberOfHours) - hoursUsedInPeriod;
	  
				  if (remainingCapacity > 0) {
					if (logDuration <= remainingCapacity) {
					  hoursUsedInPeriod += logDuration;
					  learningLogs = learningLogs.filter(l => l !== log); // Supprimer le log traité pour éviter qu'il soit compté deux fois
					} else {
					  hoursUsedInPeriod += remainingCapacity;
					  log.duration = logDuration - remainingCapacity; // Ajuster la durée restante du log pour les périodes suivantes
					}
				  }
				});
	  
				// Calculer les heures restantes pour cette banque d'heures
				console.log("hoursUsedInPeriod", hoursUsedInPeriod);
				const remainingHours = parseFloat(hourPurchased.numberOfHours) - hoursUsedInPeriod;
				console.log("remainingHours", remainingHours);
				totalHoursRemaining += Math.max(remainingHours, 0); // Ne pas ajouter de valeurs négatives
			  });*/

			  console.log("totalHoursRemaining", totalHoursRemaining);
	  
			  resolve(totalHoursRemaining);
			})
			.catch(error => {
			  console.error("Error in updateHoursRemaining:", error);
			  reject(error); // Rejeter la promesse en cas d'erreur
			});
		});
	  };


	  const calculateHoursTaken = (learningLogs, updatedHoursPurchased) => {
		// Check if updatedHoursPurchased is null or empty
		if (!updatedHoursPurchased || updatedHoursPurchased.length === 0) {
		  return 0;
		}

		console.log("updatedHoursPurchased-010",updatedHoursPurchased);
	  
		let currentIndex = 0;
	  
		learningLogs.forEach(log => {
		  let logDuration = parseFloat(log.duration) || 0;
		  const logDateTime = new Date(log.date);
	  
		  while (logDuration > 0 && currentIndex < updatedHoursPurchased.length) {
			const currentPeriod = updatedHoursPurchased[currentIndex];
			const startDateTime = new Date(currentPeriod.datePurchase);
			const endDateTime = new Date(currentPeriod.endDate);
	  
			if (logDateTime >= startDateTime && logDateTime <= endDateTime) {
			  const availableHours = currentPeriod.remainingHours;
	  
			  if (logDuration <= availableHours) {
				currentPeriod.hoursTaken += logDuration;
				currentPeriod.remainingHours -= logDuration;
				log.used = true;
				currentPeriod.usedLogDates.push(`${log.date} - ${log.duration} hour(s)`);
				logDuration = 0;
			  } else {
				currentPeriod.hoursTaken += availableHours;
				logDuration -= availableHours;
				currentPeriod.remainingHours = 0;
				currentPeriod.usedLogDates.push(`${log.date} - ${availableHours} hour(s)`);
				currentIndex++;
			  }
			} else {
			  currentIndex++;
			}
		  }
	  
		  if (logDuration > 0 && currentIndex >= updatedHoursPurchased.length && !log.used) {
			const lastPeriod = updatedHoursPurchased[updatedHoursPurchased.length - 1];
			lastPeriod.hoursTaken += logDuration;
			lastPeriod.remainingHours -= logDuration;
			log.used = true;
			lastPeriod.usedLogDates.push(`${log.date} - ${log.duration} hour(s)`);
		  }
		});
	  
		console.log("updatedHoursPurchased-123",updatedHoursPurchased);
// Calculer les heures restantes totales
const totalRemainingHours = updatedHoursPurchased.reduce((total, period, index) => {
	// S'assurer que remainingHours est bien un nombre et non une chaîne
	const remainingHours = parseFloat(period.remainingHours) > 0 ? parseFloat(period.remainingHours) : 0;
	
	console.log(`Période ${index + 1}:`);
	console.log(`- Heures restantes: ${period.remainingHours}`);
	console.log(`- Heures ajoutées au total: ${remainingHours}`);
	
	return total + remainingHours;
  }, 0);
  
  console.log(`Total des heures restantes: ${totalRemainingHours}`);
  
	  
		return totalRemainingHours;
	  };
	  
	  
	  
	  
	  


	const updateHoursRemaining = (updatedUser) => {
		
		const firestore = firebase.firestore();

		if (updatedUser.hoursPurchased && Array.isArray(updatedUser.hoursPurchased) && updatedUser.hoursPurchased.length > 0)
			return updateHoursRemainingWithPeriod(updatedUser);
	
		// Check if updatedUser or its id is null or undefined
		if (!updatedUser || !updatedUser.id) {
			console.error("Invalid updatedUser provided to updateHoursRemaining");
			return Promise.resolve(0); // Resolve with 0 instead of rejecting
		}
	
		return new Promise((resolve, reject) => {
			firestore.collection('learningLogs').where("userId", "==", updatedUser.id).get()
				.then(resp => {
					let nbHourInLearningLog = 0;
	
					resp.forEach((doc) => {
						const data = doc.data();
						nbHourInLearningLog += data.duration == '' ? 0 : data.duration;
					});
	
					let numberHourBuyUser = updatedUser.numberHourBuy;
					let numberHoursRemainingUser = numberHourBuyUser - nbHourInLearningLog;
	
					resolve(numberHoursRemainingUser);
				})
				.catch(error => {
					console.error("Error in updateHoursRemaining:", error);
					// Reject the promise with an error
					reject(error);
				});
		});
	};
	//********************************END   UserAdministration********************************************* */ 

	useEffect(() => {
		// Attendre que l'authentification Firebase soit initialisée
		if (!isLoaded(users)) {
			// Afficher un écran de chargement ou une indication de chargement
			return <Spinner />;
		}

		// Vérifier si l'utilisateur est authentifié
		if (!auth.uid) {
			// Rediriger l'utilisateur vers la page de connexion
			return <Redirect to="/signin" />;
		}
		searchResults();
		// Si l'authentification Firebase est chargée et l'utilisateur est authentifié,
		// vous pouvez maintenant charger les données de l'utilisateur depuis Firestore ici.
	}, [users]);


	// Utilisation de useCallback pour mémoriser les fonctions de gestion d'événements
	const handleSearchChange = useCallback((e) => {
		setSearch(false);
		setSearchObj({ ...searchObj, [e.target.name]: e.target.value });
	}, [searchObj]);

	const getMastermindName = (mastermindId) => {
		let name = "";
		let obj = mastermind.find((it) => it.id === mastermindId);
		name = obj != null ? obj.name : "N/A";
		return name;
	};

	const getCompanyName = (companyId) => {
		let name = "";
		let obj = company.find((it) => it.id === companyId);
		name = obj != null ? obj.name : "N/A";
		return name;
	};
	const getContractDateString = (contractId) => {
		let constractDate = "";
		let obj = contracts.find((it) => it.id === contractId);
		constractDate = obj != null ? obj.startDate + " to " + obj.endDate : "";
		return constractDate;
	};

	// Pagination des résultats
	const itemsPerPage = 10; // Nombre d'éléments à afficher par page
	const [currentPage, setCurrentPage] = useState(1);
	const [filteredResults, setFilteredResults] = useState(1);

	// Mémorisation des résultats de recherche
	const searchResults = useCallback(() => {
		if (users) {
			filteredResultsTmp = users.filter((user) => {

				let flag = false;
				if (
					(searchObj.firstname === "" ||
						(user.lastname != null &&
							user.firstname
								.toLowerCase()
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.includes(
									searchObj.firstname
										.toLowerCase()
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
								))) &&
					(searchObj.lastname === "" ||
						(user.lastname != null &&
							user.lastname
								.toLowerCase()
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.includes(
									searchObj.lastname
										.toLowerCase()
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
								))) &&
					(searchObj.email === "" ||
						(user.email != null &&
							user.email
								.toLowerCase()
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.includes(
									searchObj.email
										.toLowerCase()
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
								))
					)) {
					flag = true;
				}
				if (profile.role != null && profile.role.indexOf("admin") <= -1) {
					if (
						(searchObj.firstname === "" &&
							searchObj.lastname === "" &&
							searchObj.email === "") ||
						(searchObj.firstname.length > 0 && searchObj.firstname.length < 4) ||
						(searchObj.lastname.length > 0 && searchObj.lastname.length < 4)
					)
						flag = false;
				}
				return flag;
			});

			startIndex = (currentPage - 1) * itemsPerPage;
			endIndex = startIndex + itemsPerPage;
			//setPaginatedResults(filteredResultsTmp.slice(startIndex, endIndex))

			setPaginatedResults(filteredResultsTmp)
			setFilteredResults(filteredResultsTmp);

			return paginatedResults;
		} else {
			return []
		}
	}, [searchObj, users, profile, currentPage, itemsPerPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const CustomModal = (props) => {
		const { isOpen } = props;
		return isOpen ? <Modal {...props} /> : null;
	};

	const [show, setShow] = useState(false);
	const [studentName, setStudentName] = useState("");
	const [student, setStudent] = useState(null);

	const closeModal = () => {
		setShow(false);
	};

	const [isAdd, setIsAdd] = useState(false);
	const [isModify, setIsModify] = useState(false);

	if (!auth.uid) {
		return <Redirect to="/signin" />;
	}

	if (!isLoaded(profile) && !isLoaded(users)) {
		return (
			<div className="text-center mt-3">
				<Spinner />
			</div>
		);
	} else {


		return (
			<div>

				<div className="dashboard container mx-auto">
					<div align="right">
						<div>
							<i className="icon-container fas fa-plus-circle" style={{ marginRight: '50px', whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => openModalUser(null)}>
								&nbsp;Add a new member
							</i>
						</div>
					</div>
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row mt-3 flex-column text-center">
							<div className="col-lg-6 col-md-12">
								<FormGroup row>
									<Label sm={3} className="custom-label">Email</Label>
									<div className="col">
										<Input
											value={searchObj.email}
											onChange={handleSearchChange}
											name="email"
										/>
									</div>
								</FormGroup>
								<FormGroup row>
									<Label sm={3} className="custom-label">First name</Label>
									<div className="col">
										<Input
											value={searchObj.firstname}
											type="text"
											onChange={handleSearchChange}
											name="firstname"
										/>
									</div>
								</FormGroup>
								<FormGroup row>
									<Label sm={3} className="custom-label">Last Name</Label>
									<div className="col">
										<Input
											value={searchObj.lastname}
											type="text"
											onChange={handleSearchChange}
											name="lastname"
										/>
									</div>
								</FormGroup>
							</div>
							<div className="col-lg-6 col-md-12 align-self-end">
								<Button
									type="button"
									block
									className="custom-button"
									onClick={() => {

										if (!isSearch) {
											setSearch(true);
											searchResults();
										}
										else {
											setSearch(false);
											setSearchObj({
												email: "",
												firstname: "",
												lastname: "",
											});
										}
									}}
								>
									{isSearch ? "Reset" : "Search"}
								</Button>
							</div>
						</div>
					</form>
					{isSearch ? (
						paginatedResults && paginatedResults.length > 0 ? (
							paginatedResults.map((result) => (
								<div className="row border mt-3 py-4" key={result.id} style={{ borderRadius: '10px' }}>
									<div className="col-md-6 text-center border-right">
										{profile.role != null &&
											(profile.role.indexOf("admin") > -1 ||
												profile.role.indexOf("studentAdmin") > -1 ||
												profile.role.indexOf("professor") > -1) ? (
											<div>
												{profile.role.indexOf("studentAdmin") > -1 || profile.role.indexOf("admin") > -1 ? (
													<h3 className="font-weight-bold">
														<a href="#" onClick={() => openModalUser(result)}>
															{`${result.firstname} ${result.lastname && result.lastname}`}
														</a>
													</h3>
												) : <h3 className="font-weight-bold">

													{`${result.firstname} ${result.lastname && result.lastname}`}

												</h3>}
												<p className="m-0">{result.email}</p>
												<p className="m-0">
													{result.numberHoursRemaining}h / {result.numberHourBuy}h Remaining
												</p>
												<p className="m-0">
													<b>
														{result.company != null
															? getCompanyName(result.company)
															: "N/A"}
													</b>{" "}
													/{" "}
													<b>
														{result.mastermind != null
															? getMastermindName(result.mastermind)
															: "N/A"}
													</b>
												</p>
												<p className="m-0">
													{result.contracts != null
														? getContractDateString(result.contracts)
														: ""}
												</p>
												<p className="m-0">
													{profile.role != null &&
														profile.role.indexOf("admin") > -1
														? result.id
														: ""}
												</p>
											</div>
										) : (
											<h3 className="font-weight-bold">{`${result.firstname} ${result.lastname != null ? result.lastname : ""
												}`}</h3>
										)}
									</div>
									<div className="col-md-6 text-center d-flex align-items-center justify-content-center">
										<Button
											type="button"
											block
											color="primary"
											onClick={() => {
												setStudent(result);
												setStudentName(result.firstname + " " + result.lastname);
												setShow(true);
											}}
											className="custom-button"
										>
											Learning Log
										</Button>
									</div>
								</div>
							))
						) : (
							<h3 className="mt-4 text-center">No Results Found</h3>
						)
					) : (
						""
					)}
					<CustomModal isOpen={show} size="lg" toggle={closeModal}>
						<ModalHeader>
							<h4>{studentName}</h4>
							<h6>
								<a
									href="https://groupellipse.involve.me/teacher-lead-form"
									target="_blank"
									className="custom-link"
								>
									(Coach referral form)
								</a>
							</h6>
						</ModalHeader>
						<ModalBody>
							{student && (
								<ContactForm
									edit={true}
									profile={profile}
									student={student}
									onCancel={closeModal}
									updateHoursRemaining={updateHoursRemaining}
								/>
							)}
						</ModalBody>
					</CustomModal>

					<CustomModal isOpen={isModalOpen} size="lg" toggle={closeModalUser}>
						<ModalBody>

							<UserAdministrationModal
								isOpen={isModalOpen}
								onClose={closeModalUser}
								onSave={saveUser}
								userData={selectedUser}
								isAdd={isAdd}
								isModify={isModify}
								profile={profile}
								updateHoursRemaining={updateHoursRemaining}
							/>
						</ModalBody>
					</CustomModal>

					{isSearch && false && filteredResults && filteredResults.length > itemsPerPage && (
						<div className="pagination-buttons">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
							>
								Précédent
							</button>
							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={endIndex >= filteredResults.length}
							>
								Suivant
							</button>
						</div>
					)}



				</div>


			</div>
		);
	}
}

const mapState = (state) => {
	return {
		users: state.firestore.ordered.users,
		company: state.firestore.ordered.company,
		mastermind: state.firestore.ordered.mastermind,
		contracts: state.firestore.ordered.contracts,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([
		{ collection: "users" },
		{ collection: "company" },
		{ collection: "mastermind" },
		{ collection: "contracts" },
	]),
	withTranslation()
)(SearchUsers);
